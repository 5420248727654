<script>
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item"
import {UPopover} from "uloc-vue"
import ClienteListPage from "components/clientes/components/cliente/ListPage"
import criarClienteWindow from "components/clientes/components/cliente/window/cliente"
export default {
  name: 'ClientesLista',
  inject: ['container'],
  mixins: [],
  components: {
    ClienteListPage,
    MenuOptionsItem,
    UPopover
  },
  data () {
    return {
      routeName: ['clientes.lista'],
      listOptions: {
        loading: false,
        serverPagination: {
          page: 1,
          rowsNumber: 0,
          rowsPerPage: 20
        }
      }
    }
  },
  watch: {
    '$route.name' (v) {
      if (this.routeName.includes(v)) {
        this.$refs.list.load()
      }
    }
  },
  mounted() {
    if (this.routeName.includes(this.$route.name)) {
      this.$refs.list.load()
    }
  },
  computed: {
  },
  methods: {
    criarClienteWindow,
    criarCliente () {
      this.criarClienteWindow(null, null)
    },
    load () {
      this.$refs.list.load()
    }
  },
  meta: {
    title: 'Clientes',
    name: 'Clientes'
  }
}
</script>

<template>
  <div>
  <div v-show="routeName.includes($route.name)">
    <ul class="snav">
      <li><a @click="$router.push({name: 'clientes.dashboard'})">Clientes</a></li>
      <li class="disable">Lista</li>
    </ul>
    <div class="stitle">
      <h2>Clientes</h2>
      <div class="sopts">
        <u-btn @click="criarCliente" label="Criar" no-caps color="blue-8"/>
        <u-btn flat size="sm" class="m-l-xs">
          <i class="fa fa-ellipsis-h font-14" />
          <u-popover ref="popover" class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 15]" style="min-width: 172px">
            <div>
              <ul>
                <menu-options-item @click.native="$router.push({name: 'clientes.anotador'})" label="Anotador" close  />
                <menu-options-item label="Configurações" close  />
              </ul>
            </div>
          </u-popover>
        </u-btn>
      </div>
    </div>
    <cliente-list-page ref="list" v-show="!container.loading" :autoload="false" />
  </div>
  <router-view />
  </div>
</template>
